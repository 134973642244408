/* index.css */

/* Importing Tailwind's base styles */
@tailwind base;

/* Importing Tailwind's component styles */
@tailwind components;

/* Importing Tailwind's utility classes */
@tailwind utilities;
