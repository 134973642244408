body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

body.map-selector-active {
  overflow: hidden;
}

.w-full {
  width: 100%;
}

.h-screen {
  height: 100vh;
}

.bg-gray-100 {
  background-color: #f7fafc;
}

.relative {
  position: relative;
}

.select-none {
  user-select: none;
}

.absolute {
  position: absolute;
}

.p-4 {
  padding: 1rem;
}

.bg-gray-200 {
  background-color: #edf2f7;
}

.shadow-md {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.transition-all {
  transition: all 0.3s ease;
}

.w-64 {
  width: 16rem;
}

.w-12 {
  width: 3rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.text-gray-700 {
  color: #4a5568;
}

.block {
  display: block;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.bg-gray-400 {
  background-color: #cbd5e0;
}

.text-white {
  color: #fff;
}

.rounded {
  border-radius: 0.25rem;
}

.hover\:bg-gray-500:hover {
  background-color: #a0aec0;
}

.bg-gray-600 {
  background-color: #718096;
}

.hover\:bg-gray-700:hover {
  background-color: #4a5568;
}

.flex {
  display: flex;
}

.items-center {
  align-items: center;
}

.mr-2 {
  margin-right: 0.5rem;
}

.custommodal {
  border-radius: 10px;
  padding: 20px;
  background-color: #edf2f7;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.map-selector-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.map-selector-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.map-container {
  flex-grow: 1;
  width: 100%;
  height: 100%;
}

.take-photo-button {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 2;
  border-radius: 4px;
}

#camera-button {
  position: absolute;
  bottom: 10px;
  left: calc(50% - 60px);
  background-color: #4285F4;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 2;
  border-radius: 4px;
}

#cancel-button {
  position: absolute;
  bottom: 10px;
  left: calc(50% + 60px);
  background-color: #FF0000;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  z-index: 2;
  border-radius: 4px;
}

#bound-box {
  pointer-events: none;
  position: absolute;
  top: 100px;
  left: 50%;
  transform: translateX(-50%);
  width: 640px;
  height: calc(100% - 200px);
  border: 4px solid white;
  z-index: 3;
}

.search-container {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  z-index: 2;
}

.search-input {
  padding: 10px;
  font-size: 16px;
  width: 300px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.photo-preview,
.downloadpdfmodal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  z-index: 1001;
  max-width: 90vw;
  max-height: 90vh;
  overflow: auto;
}

.downloadpdfmodal {
  width: 80%;
}

.photo-preview img {
  max-width: 100%;
  max-height: calc(90vh - 100px);
  object-fit: contain;
  border-radius: 4px;
  user-select: none;
  -webkit-user-drag: none;
}

.photo-preview-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.accept-button,
.retake-button,
.undo-button {
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin: 0 5px;
}

.accept-button {
  background-color: #28a745;
  color: white;
}

.retake-button {
  background-color: #ffc107;
  color: white;
}

.undo-button {
  background-color: #17a2b8;
  color: white;
}

.roof-selection-prompt {
  position: absolute;
  top: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px;
  border-radius: 4px;
}

.roof-selection-box {
  position: absolute;
  border: 2px solid #4285F4;
  background-color: rgba(66, 133, 244, 0.3);
  pointer-events: none;
}

.detecting-roof {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}